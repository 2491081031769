import React from "react"


function getRandInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    //The maximum is exclusive and the minimum is inclusive
    return Math.floor(Math.random() * (max - min)) + min;
}


const VOWELS = "aeiou"
const NON_VOW = "bcdfghjklmnpqrstvwxyz"
//const PUNCT = ",.!?"
//CHARS = "abcdefghijklmnopqrstuvwxyz"

export default class Test extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            count: 0,
            content: "",
            up: true
        }
        //this.genChar = this.genChar.bind(this)
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            document.title = `n${"z".repeat(this.state.count%4+1)}`;
            this.setState({count: this.state.count + 1})
        }, 5000);
        //this.contint = setInterval(() => this.renderChar(), 100)
        this.renderChar()
    }

    componentWillUnmount() {
          clearInterval(this.interval);
          //clearInterval(this.contint);
    }

    render() {
        return(
            <div>{this.state.content}</div>
        )
    }

    genWord() {
        let rnd = 1
        let word = ""
        while (rnd > word.length / 20) {
            rnd = Math.random()
            //if (rnd < 0.3):
            switch (true) {
                case rnd < 0.3:
                    word += VOWELS[getRandInt(0, VOWELS.length)]
                    break
                default:
                    word += NON_VOW[getRandInt(0, NON_VOW.length)]
            }
        }
        rnd = Math.random()
        if (rnd < 0.2 || this.state.up) {
            word = word[0].toUpperCase() + word.slice(1)
            this.setState({up: false})
        }
        rnd = Math.random()
        switch (true) {
            case rnd < 0.8:
                word += " "
                break
            case rnd < 0.9:
                word += ". "
                this.setState({up: true})
                break
            case rnd < 0.95:
                word += "? "
                this.setState({up: true})
                break
            default:
                word += "! "
                this.setState({up: true})

        }
        return word
    }

    async renderChar() {
        while (true) {
            let word = this.genWord()
            for (const c of word) {
                this.setState({content: this.state.content + c})
                if (c !== " ") {
                    await new Promise(r => setTimeout(r, 50))
                }
            }
            if (this.state.content.length > 1000) {
                this.setState({content: ""})
            }
        }
    }

}

